<nav class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute" color-on-scroll="500">
    <div class="container">
      <div class="navbar-wrapper">
        <a class="navbar-brand d-none d-sm-none d-md-block" [routerLink]="['/home']"><img src="../../../assets/img/doc/logo-transparent.png" class="logotransparent" alt=""></a>
        <a class="navbar-brand d-block d-sm-block d-md-none" [routerLink]="['/home']"><img src="../../../assets/img/doc/logo-transparent.png" class="logotransparent" alt=""> </a>
      </div>
      <button mat-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav" style="  color: #646469;">

          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/account/register']">
              <i class="material-icons">person_add</i> S'inscrire
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/account/login']">
              <i class="material-icons">fingerprint</i> se connecter
            </a>
          </li>
          <!-- <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/pages/lock']">
              <i class="material-icons">lock_open</i> Lock
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
  <router-outlet></router-outlet>

