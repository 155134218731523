import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/models';
import { AccountService } from '@app/services';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.less']
})
export class LandingPageComponent implements OnInit {
  currentUser: User;
  isTested?:boolean;

  constructor(
    private router: Router,
    private accountService: AccountService
  ) {
    this.currentUser = this.accountService.userDecodedValue;
   }

  ngOnInit(): void {
    setTimeout(() => {
      console.log(this.currentUser.is_tested,'DARAAAAAAAA')
      this.isTested=this.currentUser.is_tested
    }, 1000);
    (function responsiveMenu() {
      var menuList = document.getElementById('menu-list');
      let buttonMenu = document.getElementById('menu-btn');
      menuList.style.maxHeight = '0px';
  
      buttonMenu.addEventListener("click", function() {
          if (menuList.style.maxHeight == '0px') {
            menuList.style.maxHeight = '130px';
          } else {
            menuList.style.maxHeight = '0px';
          }
      });
      
  })()
  }

}
