import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/helpers';
import { PlaySurveyComponent } from './play-survey/play-survey.component';
import { ResultComponent } from './result/result.component';
import { CurrentDomainQstComponent } from './current-domain-qst/current-domain-qst.component';


const routes: Routes = [
  {
    path: '',
   children: [
       { 
         path: 'play-survey', 
         component: PlaySurveyComponent,
         children:[
          {
           path : ':id',
           component: CurrentDomainQstComponent
          }
        ]
       },
       { 
         path: 'result-survey', 
         component: ResultComponent 
        },
      //  { path: 'play-survey/:id', component: CurrentDomainQstComponent },
   ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UiRoutingModule { }
