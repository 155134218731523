<div class="card">
    <div class="card-header card-header-rose text-center">
        <h4 style="text-transform:uppercase;">Conditions générales d'utilisation</h4>
        <p class="card-category">Évaluez votre niveau de Maturité Digitale​</p>
    </div>

    <div class="card-body">
            <!-- <div class="row">
                <div class="col-md-6 ml-auto mr-auto text-center">
                  <h3 class="title">Évaluez votre niveau de Maturité Digitale​</h3>
                </div>
              </div> -->

        <mat-dialog-content class="mat-typography"  style="border-radius: 0%;">
      
            <div class="row">

                    <div class="col-sm-4 col-md-4">
                        <div class="card card-pricing card-plain">
                          <h6 class="card-category">Etape 1</h6>
                          <div class="card-body">
                 
                            <div class="row">
                              <div class="col-md-4">
                                <img class="img" src="./assets/img/doc/imagesacceuil/Image2.png" >
                              </div>
                              <div class="col-md-8">
                                <h3 class="title_align">Etape 1 - Remplissez le questionnaire</h3>
                              </div>
                            </div>
                            <p class="text_align">   L’évaluation comprends 80 questions réparties dans 8 domaines. ​
                                Pour chaque question sélectionnez la réponse qui décrit le mieux votre organisation. ​  
                                Vous pouvez compléter l’évaluation en plusieurs fois si cela est nécessaire. 
                                Pour cela, il vous suffira de vous reconnecter à l’application (vos réponses précédentes seront sauvegardées)</p>
                          </div>
                        </div>
                    </div>
                <div class="col-sm-8 col-md-8">
                    <div class="col-lg-12 col-md-12 text-center">
                        <img class="img" src="./assets/img/doc/imagesacceuil/Image1.png" style="width:400px" >

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card card-pricing card-plain">
                                <h6 class="card-category">Etape 2</h6>
                         
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <img class="img" src="./assets/img/doc/imagesacceuil/Image3.png" >
                                    </div>
                                    <div class="col-md-9">
                                      <h3 class="title_align"> Etape 2 - Evaluez la maturité </h3>
                                    </div>
                                  </div>
                                <p class="text_align">
                                    Votre résultat de maturité est donné sur une échelle de 1 à 10. En fonction du score, votre organisation sera classée dans une des 5 catégories
                                </p>
                                </div>
                              </div>
                         
                        </div>
                        <div class="col-md-6">
                            <div class="card card-pricing card-plain">
                                <h6 class="card-category">Etape 3 </h6>
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <img class="img" src="./assets/img/doc/imagesacceuil/Image4.png" >
                                    </div>
                                    <div class="col-md-9">
                                      <h3 class="title_align">Etape 3 - Recevez votre évaluation détaillée</h3>
                                    </div>
                                  </div>
                                  <p class="text_align">
                                    Un rapport vous sera envoyé par mail avec l’analyse détaillée de votre évaluation
                                </p>
                                </div>
                              </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        
        </mat-dialog-content>
        <!-- <mat-dialog-actions align="end"  class="submitbuttoncontainer">
            <button mat-button  class="btn btn-default submitbutton" mat-dialog-close>ANNULER</button>
            <button mat-button class="btn btn-default submitbutton"[mat-dialog-close]="true" cdkFocusInitial>CONTINUER</button>
        </mat-dialog-actions> -->
    </div>
    <div class="footer text-center">
        <!-- <mat-dialog-actions > -->
        <!-- <button mat-button mat-dialog-close>Cancel</button> -->
        <!-- <button  class="btn btn-lg btn-secondary btn-block"[mat-dialog-close]="data" cdkFocusInitial>SOUMETTRE</button> -->
        <!-- [routerLink]="['/account/login']" -->
        
        <button mat-button  
                class="btn btn-lg btn-danger btn-block" 
                mat-dialog-close
                type="submit" (click)="SignOut()">Annuler</button><br>
        <button mat-button 
                class="btn btn-lg btn-default btn-block" 
                mat-dialog-close>Continuer</button><br>

        <!-- </mat-dialog-actions> -->
    </div>

</div>
