import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component'
import { AuthGuard } from './helpers';
import { ConditionReminderComponent } from './ui/condition-reminder/condition-reminder.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const usersModule = () => import('./users/users.module').then(x => x.UsersModule);

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: LandingPageComponent,
      },

      {
        path: 'about',
        component: ConditionReminderComponent,
      },
    // { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    // { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
      // { 
      //   path: 'account', 
      //   loadChildren: './account/account.module#AccountModule'
      // },
      {
        path: '',
        component:AuthLayoutComponent,
        children: [{
          path: 'account',
          loadChildren: './account/account.module#AccountModule'
        }]
      },  
      { 
        path: 'survey', 
        loadChildren: './ui/ui.module#UiModule',
        canActivate:[AuthGuard]
      },


];

@NgModule({
    imports: [
      CommonModule,
      BrowserModule,
      RouterModule.forRoot(routes,{
         useHash: true,
        scrollPositionRestoration : 'enabled'
      })   
    ],
    exports: [
      RouterModule
    ],
})
export class AppRoutingModule { }
