<div class="card card-plain qst">
  <!-- *ngIf="showLoader==true" -->
  <div>
    <div class="tab-pane active">
      <!-- <div class="row"> -->


        <div class="col-md-12">
          <div class="card-body">
            <div class="col-md-11 ml-auto mr-auto questions-card-header">

              <div class="row">
                <div class="col-md-2">
                  <img alt="image" [src]="'http:///maturite-axian.orbusdigital.com/' + questions?.domaine_picture" style="width: 100%;" />
                </div>
                <div class="col-md-10 domaine-title">
                  <h3 class="title-module ">{{questions?.domaine_name |uppercase}} </h3>
        
                  <span class="questions-txt">
                    Vous êtes à <b style="font-weight: bold;" class="note-txt">{{pourcentage}}%</b> de réponses concernant la compétence actuelle
                    <!-- {{questions?.domaine_name |uppercase}} -->
                  </span>
                </div>
              </div>
              <!-- <mat-divider></mat-divider> -->
              <br>
              <br>
              <br>
              <div *ngFor="let qst of questions?.td_questions;let i=index">
                <mat-card>
                  <h3 class="question-txt">{{i+1}})
                    {{qst.question_wording}}
                  </h3>
                  <mat-divider></mat-divider>
                  <br>
                  <div class="center-blog">
                    <div class="bd-content">
                      <mat-card-content class="bd-content">
                        <mat-radio-group>
                          <div *ngFor="let resp of qst.td_responses; let idx = index">
                            <mat-radio-button class="example-radio-button" [checked]="resp.ischecked != 0"
                              [value]="resp" name="{{resp.questions_id}}" (change)="radioChange($event,resp)"
                              [(ngModel)]="resp.ischecked" (percentageEvent)="getPercentage(pourcentage)"
                              ngDefaultControl>
                              <div class="col-md-12 reponse-txt">
                                {{resp.response_wording}}
                              </div>
                            </mat-radio-button>
                          </div>
                        </mat-radio-group>
                      </mat-card-content>
                    </div>
                  </div>
                </mat-card>
                <br><br>
              </div>
            </div>
          </div>
          <mat-card-actions>
            <mat-action-row class="submitbuttoncontainer">
              <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <!-- <button type="button" class="btn btn-info submitbutton" (click)="onSubmit()">Valider le test</button> -->
                  <div class="row">
                    <div class="col-sm-6 float-right">
                      <button type="button" class="btn btn-default submitbutton" (click)="onPrevious()"
                        [disabled]="domaineID == 1">précedent</button>
                    </div>
                    <div class="col-sm-6 float-right">
                      <button type="button" class="btn btn-default submitbutton" (click)="nextStep()"
                        [disabled]="domaineID == 8">suivant</button>
                    </div>
                  </div>
                </div>

              </div>
            </mat-action-row>
          </mat-card-actions>
          <div>
          </div>
        </div>

      <!-- </div> -->

  </div>


</div>

</div>
