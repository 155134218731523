import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ActivityArea, Country, Domaine, TypeEntreprise, User } from '@app/models';
import { JwtHelperService } from "@auth0/angular-jwt";


@Injectable({ providedIn: 'root' })
export class AccountService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public users: Observable<User>;

    jwtHelper = new JwtHelperService();
    roles:number;
    authenticate = false;
    user=new User()


    constructor(
        private router: Router,
        private http: HttpClient,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.currentUser = this.currentUserSubject.asObservable();
        // console.log(this.currentUserSubject,"adonay")

    }

    public get userValue(): User {
        // this.user = this.jwtHelper.decodeToken(localStorage.getItem('user'));
        return this.currentUserSubject.value;
    }

    public get userDecodedValue(): User {
        this.user = this.jwtHelper.decodeToken(localStorage.getItem('currentUser'));
        // console.log(this.user,"adonayyyy")
        return this.user;
    }

    login(email, password) {
        return this.http.post<User>(`${environment.apiUrl}/login`, { email, password })
            .pipe(map(user => {
                if (user && user.token) {
                // console.log('token = ' + user.token);
                // console.log('user = ' + JSON.stringify(user.token));
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('currentUser', JSON.stringify(user.token));
                this.roles = this.jwtHelper.decodeToken(user.token);
                // console.log('r === ' + this.roles);
                this.currentUserSubject.next(user);
                this.authenticate = true;
                return user;
                }
                // return user;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        localStorage.removeItem('user');
        this.currentUserSubject.next(null);
        this.authenticate = false;
        this.router.navigate(['/account/login']).catch();
    }

    public getRole() {
        return (this.roles = this.jwtHelper.decodeToken(localStorage.getItem('currentUser')).roles_id);
      }
    
      public isAuthentificated(): boolean {
        if (localStorage.getItem('currentUser')) {
          const token = localStorage.getItem('currentUser');
        //   console.log('la valeur du token', token)
          return !this.jwtHelper.isTokenExpired(token);
        }
      }
    
    //   public getAuthenticatedUser(): User {
    //     let u = new User();
    //     if (localStorage.getItem('currentUser')) {
    //       console.log('username = ' + this.jwtHelper.decodeToken(localStorage.getItem('currentUser')).sub);
    //       this.userSrc.getByUsername(this.jwtHelper.decodeToken(localStorage.getItem('currentUser')).sub).subscribe(
    //         value => {
    //           u = value;
    //         },
    //         error1 => {  
    //           u = null;
    //         }
    //       );
    //     } else {
    //       console.log('non auth !!!');
    //     }
    //     return u;
    //   }


    register(user: User) {
        // return this.http.post(`${environment.apiUrl}/register`, user);

        return this.http.post<User>(`${environment.apiUrl}/register`,user)
        .pipe(map(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
            // this.currentUserSubject.next(user);
            // return user;
            if (user && user.token) {

            console.log('token = ' + user.token);
            console.log('user = ' + JSON.stringify(user));
            localStorage.setItem('currentUser', JSON.stringify(user.token));
            this.roles = this.jwtHelper.decodeToken(user.token).roles_id;
            console.log('r === ' + this.roles);
            this.currentUserSubject.next(user);
            this.authenticate = true;
            }
            return user;
        }));
    }


    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.apiUrl}/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.currentUserSubject.next(user);
                }
                return x;
            }));
    }

    public getActicvityArea() {
        return this.http.get<ActivityArea[]>(`${environment.apiUrl}/activities`);
    }
    public getDomaines() {
        return this.http.get<Domaine[]>(`${environment.apiUrl}/init-domaines`);
    }

    public getAllCountries() {
        return this.http.get<Country[]>(`${environment.apiUrl}/countries`);
    }
    public getAllEnterpriseType() {
        return this.http.get<TypeEntreprise[]>(`${environment.apiUrl}/entreprise-type`);
    }
}