<h4 class="home-subtitle-colored" style="margin: auto; width: 50%; margin-bottom: 2%;">Rappel des niveaux de maturité</h4>
<div>

  <table class="table-niv">
    <thead>
      <tr>
        <td colspan="2">
          Notes
        </td>
        <td>
          Profils
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let maturity of maturities">
        <td>
          <div class="square"></div>
        </td>
        <td>
          {{maturity.note_min}} à {{maturity.note_max}}
        </td>
        <td>
          {{maturity.level_name}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
