import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '@app/services';

@Component({
  selector: 'app-condition-reminder',
  templateUrl: './condition-reminder.component.html',
  styleUrls: ['./condition-reminder.component.less']
})
export class ConditionReminderComponent implements OnInit {

  constructor(
    private accountService:AccountService,
    // public dialogRef: MatDialogRef<ConditionReminderComponent>,
  ) { 
    // dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }
  SignOut() {
    this.accountService.logout();
  }


}
