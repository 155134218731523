<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#C80F2D" type="ball-scale-multiple" [fullScreen]="true">
  <p style="color: #fff"> Chargement... </p>
</ngx-spinner>
<app-navbar></app-navbar>
<br><br><br>
<div class="main-content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header card-header-rose">
        <div class="nav-tabs-navigation">
          <div class="nav-tabs-wrapper">
            <h3 style="text-transform:uppercase;text-align: center;" class="question-txt"> Votre niveau de maturité digitale</h3>
            <!-- <h4 style="font-weight: bold;text-align: center;font-size: 21px;"></h4> -->
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row" style="margin-top: 20px;">
          <div class="col-md-5 text-center">
            <!-- <img src="../../../assets/img/doc/win.png" class="centrer" /> -->
            <img alt="image" [src]="'http:///maturite-axian.orbusdigital.com/' + resultTab?.tp_niv_maturite.picture" style="widows: 300px;height: 150px;" />
            <br />
            <div style="text-align: center;font-weight:bold ;">{{resultTab?.tp_niv_maturite?.level_mention}}</div>
            <div style="text-align: center;line-height:2; font-size:14px; font-weight: normal; color: #646469;">Votre score est de <span class="note-txt">{{resultTab?.score}} / 10</span></div>

            <div style="text-align: center;"><span class="note-txt">{{resultTab?.tp_niv_maturite.level_name}}</span></div>
          </div>
          <div class="col-md-7">
            <div>
              <!-- <app-maturity-level></app-maturity-level> -->
              <h3 class="home-title-colored">Votre synthèse</h3>
              <h4 class="home-subtitle-colored">Avec un score de <span class="note-txt">{{resultTab?.score}}/10</span> vous êtes
                <b class="card-title">{{resultTab?.tp_niv_maturite?.level_name}}</b></h4>
              <br>
              <p class="interpretation">
                {{resultTab?.tp_niv_maturite?.interpretation}}
              </p>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-6">

            <app-maturity-level></app-maturity-level>

          </div>
          <div class="col-md-6">
            <div class="chart-wrapper">
              <canvas baseChart [datasets]="radarChartData" [options]="radarChartOptions"
                [labels]="radarChartLabels" [chartType]="radarChartType">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
