import { Deserializable } from "./deserializable.model";

export class tp_niv_maturite implements Deserializable{
    id : number;
    level_name:string;
    note_min: number;
    note_max: number;
    interpretation: string;
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}