import { Deserializable } from "./deserializable.model";
import { Responses } from "./response.model";

export class Questions implements Deserializable{
    id : number;
    question_code: string;
    question_wording: string;
    domaine_id: number;
    td_responses:Responses[];

    deserialize(input: any) {
        Object.assign(this, input);
        // this.td_responses=new Responses().deserialize(input.td_responses);
        return this;
    }
}
