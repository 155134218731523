import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Domaine, JsonData, Questions, question_response } from '@app/models';
import { environment } from '@environments/environment';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  seconds: number;
  domaines: Domaine[];

  surveySource: BehaviorSubject<number> = new BehaviorSubject(0);
  surveySourceID: BehaviorSubject<number> = new BehaviorSubject(0);
  surveySourceDomaines: BehaviorSubject<any> = new BehaviorSubject(null);
  surveySourceQuestions: BehaviorSubject<any> = new BehaviorSubject(null);

  private currentDomaineValue: BehaviorSubject<Domaine>;
  public currentDomain: Observable<Domaine>;



  constructor(private http: HttpClient) {
    this.currentDomaineValue = new BehaviorSubject<Domaine>(JSON.parse(localStorage.getItem('domains')));
    this.currentDomain = this.currentDomaineValue.asObservable();

  }

  public get domaineValue(): Domaine {
    return this.currentDomaineValue.value;

}
  getAllDomainWithQuestionsAndResponses(): Observable<Domaine[]> {
    return this.http.get<Domaine[]>(`${environment.apiUrl}/questions-responses`)
      .pipe(
        map(
          data => data['data']
            .map(data => new Domaine()
              .deserialize(data))
        )
      );
  }



  getAllDomainWithQuestionsResponses(): Observable<[]> {
    return this.http.get<any>(`${environment.apiUrl}/questions-responses`)
      .pipe(
        tap(_ => ('fetched user result')),
        catchError(this.handleError<[]>('getOneUserSurveyResult', []))
      );
  }


  getAllDomains(): Observable<[]> {
    return this.http.get<any>(`${environment.apiUrl}/domaines`)
      .pipe(
        tap(_ => ('fetched user result')),
        catchError(this.handleError<[]>('getOneUserSurveyResult', []))
      );
  }

  getUserQuestionsResponse(id:string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/save-test/${id}`);
  }


  getOneUserSurveyResult(): Observable<[]> {
    return this.http.get<any>(`${environment.apiUrl}/result-user`)
      .pipe(
        tap(_ => ('fetched user result')),
        catchError(this.handleError<[]>('getOneUserSurveyResult', []))
      );
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }


  /** GET Domaines from the server */
  getDomaines(): Observable<Domaine[]> {
    const url = `${environment.apiUrl}/domaines`;
    return this.http.get<Domaine[]>(url)
      .pipe(
        tap(_ => console.log('bakhna'), _ => console.log('fetched domaines')),
        catchError(this.handleError('getDomaines', []))
      );

  }

  getQuestionsByDomain(id) {
    return this.http.get(`${environment.apiUrl}/questions/domaines/${id}`);
  }

  restartTest() {
    const url = `${environment.apiUrl}/restart`;
    return this.http.get(url)
      .pipe(
        tap(_ => console.log('bakhna'), _ => console.log('restart teste')),
        catchError(this.handleError('restartTest', []))
      );

  }


  addOneResponse(data:question_response): Observable<question_response> {
    return this.http.post<question_response>(`${environment.apiUrl}/save-response`, data);
  }


 addSingleResponse(qst_resp:any): Observable<question_response> {
  // const body=JSON.stringify(qst_resp);

    return this.http.post<question_response>(`${environment.apiUrl}/save-response`, qst_resp).pipe(
      tap((newResp: question_response) => console.log(`added question w/ id=${newResp.response_id}`)),
      catchError(this.handleError<question_response>('addHero'))
    );
  }

getOneDomainPercentage(id){
  return this.http.get(`${environment.apiUrl}/pourcentage/domaine/${id}`);
}


getAllTestPercentage(): Observable<[]> {
  return this.http.get<[]>(`${environment.apiUrl}/total-pourcentage`)
    .pipe(
      tap(_ => ('fetched total percentage result')),
      catchError(this.handleError<[]>('getAllTestPercentage', []))
    );
}

  getpourcentage() {
    const url = `${environment.apiUrl}/total-pourcentage`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => console.log('fetched pourcentage')),
        catchError(this.handleError('touss nieuwoul', 0))
      );

  }


}
