import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild,HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Domaine, question_response, ServerResponse, User } from '@app/models';
import { AccountService, AlertService, SurveyService } from '@app/services';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
declare var $: any;



@Component({
  selector: 'app-play-survey',
  templateUrl: './play-survey.component.html',
  styleUrls: ['./play-survey.component.less']
})
export class PlaySurveyComponent implements OnInit {

  domaines: [];
  domaine: [];

  currentDomaine;
  questions = [];
  public domains: Domaine[];
  doms:Domaine
  currentIndex;

  td_responses: any = [];
  public resp: any = [];
  finalArray: any[] = [];

  checkedRadioVerifTab = [];
  fromPrevious: boolean = false;
  returnUrl: string;
  private requestId: number;
  id: any
  public res = new ServerResponse()
  globalPercentage;
  qcmProgressValue;
  domaineID: number;
  idd: number;
  user: User;


  constructor(
    private surveyService: SurveyService,
    private route: Router,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private accountService:AccountService
  ) {
    this.user = this.accountService.userDecodedValue;
    this.qcmProgressValue = 0;
    this.domaineID = 1;
  }

  ngOnInit(): void {
    this.spinner.show()
    this.getDomains()
    this.getAllTestPercentage()
    this.getPourcentageTotal();
    this.getAllDomaines();  

  }


  ngAfterViewInit() {
    this.selectDomaine(1);
  }



  // tslint:disable-next-line: typedef
  restartTest() {
    this.surveyService.restartTest().subscribe(
      res => {
        if (res['code'] == 200) {
          this.getDomains();
          this.getQuestions();
          this.getPourcentageTotalFromBackend();
          
        } 
        window.scroll(0, 0);
      }
    )

  }

  getAllTestPercentage() {
    this.surveyService.getAllTestPercentage().subscribe(
      res => {
        this.globalPercentage = res['data']

      }
    )

  }

  selectDomaine(id: number){
    this.route.navigate(['/survey/play-survey/'+id]);
    this.getDomaineID();
    this.domaineID= id;
    window.scrollTo(0, 0);
    // this.getDomains();
  }

  getPourcentageTotal() {
    this.surveyService.surveySource.subscribe(
      data => {
        this.qcmProgressValue = data;
      }, (error) => console.log(error)
    )
  }

  getDomaineID() {
    this.surveyService.surveySourceID.subscribe(
      data => {
        this.domaineID = data;
      }, (error) => console.log(error)
    )
  }


  public getDomains() {
    this.surveyService.getAllDomains().subscribe(
      domains => {
        this.domaines = domains['data']
        this.surveyService.surveySourceDomaines.next(this.domaines);
        this.id = this.domaines.find(item => item['id'] === 1)['id'] ;
        this.spinner.hide();
      });
  }

  getAllDomaines() {
    this.surveyService.surveySourceDomaines.subscribe(
      data => {
        this.domaines = data;
      }, (error) => console.log(error)
    )
  }


  showSwal(type) {
    if (type == 'restart-test') {
      Swal.fire({
        title: 'êtes-vous sûr de vouloir continuer?',
        text: "Cette operation est irreversible!",
        icon: 'warning',
        showCancelButton: true,
        // background: 'gray',
        cancelButtonColor: '#C80F2D',
        confirmButtonColor: '#646469',
        iconColor: '#C80F2D',
        customClass: {
          cancelButton: 'btn btn-danger',
          confirmButton: 'btn btn-success',
        },
        cancelButtonText: 'ANNULER',
        confirmButtonText: 'CONFIRMER',
        buttonsStyling: true,
        reverseButtons: true,
        allowOutsideClick:false,

      }).then((result) => {
        if (result.value) {
          this.restartTest()
          Swal.close();
        }
      })
    }else if(type == 'submit-test'){
      Swal.fire({
        title: 'êtes-vous sûr de vouloir continuer',
        text: "Cette opération est irreversible!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#646469',
        cancelButtonColor: '#C80F2D ',
        iconColor: '#C80F2D',
        customClass: {
          cancelButton: 'btn btn-danger',
          confirmButton: 'btn btn-success',
        },
        cancelButtonText: 'ANNULER',
        confirmButtonText: 'CONFIRMER',
        buttonsStyling: true,
        allowOutsideClick:false,
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.onSubmit()
          Swal.fire(
            {
              title: 'Sauvegardé!',
              text: 'Votre test est enregistré avec succès.',
              icon: 'success',
              confirmButtonColor: '#646469',
              iconColor: '#646469',
              customClass: {
                confirmButton: "btn btn-success",
              },
              buttonsStyling: true
            }
          )
        }
      })
    }
  }


  onSubmit() {

    this.surveyService.getUserQuestionsResponse(this.user.id)
      .subscribe(
        res => {
          if (res == null) {
            return;
          } else {
            this.route.navigate(['/survey/result-survey'], { relativeTo: this.router });
             console.log(res,'Resultat');
          }
        },
        err => console.log('HTTP Error', err)
      );
  }

  brillance(id:number){
    if(id == this.domaineID){
      return 'background-color:#C80F2D';
    }
    return '';
  }

  scrollingSidebar(){
    if(window.pageYOffset >= 200){
      return 'domaine-sidebar-scroll';
    }else{
      return 'domaine-sidebar';
    }
  }



   
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event){
    
  }

  getPourcentageTotalFromBackend(){
    this.surveyService.getpourcentage().subscribe(
      res=>{
        this.surveyService.surveySource.next(res.data);
      }
    )
  }


  public getQuestions() {
    this.surveyService.getQuestionsByDomain(1).subscribe(
      res => {
        this.surveyService.surveySourceQuestions.next(res['data']);
      });
  }



}
